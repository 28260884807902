<!--审核中-->
<template>
  <div class="pd_page"> 
    <div class="page_one">
      <div class="one_name">
        <div class="name_item">
          <a class="name_des">就诊人:</a>{{ detail.patName }}
        </div>
        <div class="name_item_2">
          <a class="name_des">性别:</a>{{ detail.xb=='1'?'男':'女' }}
        </div>
      </div>
      <div class="one_name">
        <div class="name_item">
          <a class="name_des">年 龄:</a>{{ detail.age }}岁
        </div>
        <div class="name_item_2">
          <a class="name_des">科室:</a>{{ detail.deptName }}
        </div>
      </div>
      <div class="name_item_3">
        <div class="one_name">
          <a class="name_des">日 期:</a>{{ detail.preCreateTime }}
        </div>
      </div>
      <div class="name_item_3">
        <div class="one_name">
          <a class="name_des">临床诊断:</a>{{ detail.mainDiagnosisName }}
        </div>
      </div>
    </div>
    <div class="page_two">
      <div class="two_title">
        RP
      </div>
      <div v-if="detail.preType=='3'">
        <div v-for="(item, index) in detail.prescriptionDetailDrugList" :key="index" class="two_content">
          <div class="content_item">
            <div class="item_name">
              {{ item.drugName }}
            </div>
            <div class="name_item_2">
              {{ item.spec }}  x{{ item.drugAmount }}
            </div>
          </div>
          <div v-if="detail.prescriptionDetailDrugList.length==index+1" class="content_item">
            <div class="name_item">
              用法用量
            </div>
            <div class="name_item_2">
              服用{{ item.days }}天, {{ item.usage }}, {{ item.frequency }}
            </div>
          </div> 
        </div>
      </div>
      <div v-else>
        <div v-for="(item, index) in detail.prescriptionDetailDrugList" :key="index" class="two_content">
          <div class="content_item">
            <div class="item_name">
              {{ item.drugName }}
            </div>
            <div class="name_item_2">
              {{ item.spec }}  x{{ item.drugAmount }}
            </div>
          </div>
          <div class="content_item">
            <div class="name_item">
              用法用量
            </div>
            <div class="name_item_2">
              {{ item.usage }}, {{ item.frequency }}, 每次{{ item.singleUsage }}{{ item.singleUsageUnit }}
            </div>
          </div>
          <div class="content_item">
            <div class="name_item">
              服药天数
            </div>
            <div class="name_item_2">
              {{ item.days }}天
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page_two">
      <div class="two_content">
        <div class="content_item">
          <div class="name_item">
            医师: {{ detail.docName }}
          </div>
          <div class="name_item_2">
            审核医师: {{ detail.examineDocName }}
          </div>
        </div>
        <div class="content_item">
          <div class="name_item">
            调配: {{ detail.dispensingDocName }}
          </div>
          <div class="name_item_2">
            核发医师: {{ detail.checkDocName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gjcModel } from '../../../api/gjcApi'

export default {
    name:'PrescriptionDetail',
    data(){
        return{
            detail:{}
        }
    },
    mounted(){
        this.getDetail()
    },
    methods:{
        getDetail(){
            let params={
                preId:this.$route.query.id
            }
            gjcModel.getPrescriptionDetailData(params).then(res=>{
                this.detail = res.data
            })
        }
    }
}
</script>
<style scoped lang='less'>
@import "../../../assets/styles/variable.less";
    .pd_page{
        background-color: white;
        .page_one{
            border-bottom: 20px solid #F5F5F5;
            padding: 20px 24px;
            font-size: 30px;
            .one_name{
                display: flex;
                // justify-content: space-between;
                line-height: 50px;
                color: #333;
                .name_item{
                    width: 250px;
                    // height: 30px;
                }
                .name_item_2{
                    width: 400px;
                }
                .name_item_3{
                    width: 100%;
                }
                .name_des{
                    margin-right: 20px;
                    color: #666;
                }
            }
        }
        .page_two{
            padding: 20px 24px;
            border-bottom: 20px solid #F5F5F5;
            .two_title{
                font-size: 32px;
                color: #333;
                font-weight: bold;
            }
            .two_content{
                margin-top: 20px;
                font-size: 30px;
                color: #333;
                .content_item{
                    display: flex;
                    justify-content: space-between;
                    line-height: 50px;
                    // border-bottom: #333 solid 1px;
                    .item_name{
                    }
                }
            }
        }
    }
</style>
